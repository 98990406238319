
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.faq__title {
  margin-bottom: 32px;

  @include media(lg) {
    width: 768px;
    margin-bottom: 48px;
  }
}
